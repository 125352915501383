<template>
    <transition>
        <component :is="current" @switch="switchPage"></component>
    </transition>
</template>

<script>
    import Detail from "./detail.vue";
    import List from "./list.vue";
    export default {
        data() {
            return {
                current: "List",

                dUnitList: [],
                uUnitList: [],
                useTypes: [],
                options: null
            };
        },
        methods: {
            switchPage(options) {
                if (this.current === "List") {
                    this.current = "Detail";
                } else {
                    this.current = "List";
                }
                this.options = options;
            },
            initDicts() {
                this.$http.post("/dict/getDictValuesByDictId", "dictuseway").then(res => {
                    this.useTypes = res.detail;
                });
                this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: "",
                        unitType: "3"
                    })
                    .then(res => {
                        this.dUnitList = res.detail;
                    });
                this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: "",
                        unitType: "1"
                    })
                    .then(res => {
                        this.uUnitList = res.detail;
                    });
            }
        },
        created() {
            this.initDicts();
        },
        components: {
            Detail,
            List
        }
    };
</script>
<style></style>
